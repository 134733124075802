<template>
  <v-container fluid class="pb-8">
    <v-row>
      <v-col>
        <h3 class="mb-2">Sync accounts</h3>

        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': datatables_rowsPerPageItems,
          }"
          :server-items-length="totalItems"
          class="elevation-1"
        >
          <template v-slot:loading>
            Loading items..
          </template>

          <template v-slot:[`footer.page-text`]="props">
            {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
          </template>

        </v-data-table>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NKWSyncAccountsTable',

  props: {
    nkwID: { type: Number, required: true },
    updateKey: { type: String, required: true },
  },

  data () {
    return {
      headers: [
        { text: 'Ad network', value: 'ad_network' },
        { text: 'Account name', value: 'account_name' },
        { text: 'Account ID', value: 'account_id' },
        { text: 'Account number', value: 'account_number' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'nkwSyncAccounts_options',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    dataEndpoint () {
      return '/a/nkw/keywords/' + this.nkwID + '/sync-accounts'
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
    updateKey: {
      handler () {
        this.options.page = 1;
        this.fetchData()
      },
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], [])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },

  created: function () {
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // this table should always start at page 1
    this.options.page = 1;
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}
</style>